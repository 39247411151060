

export const InstagramIcon = ({size="50", color="white"}) => {
  return(
    <svg width={size} height={size} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_26_237)">
        <path d="M25 0C18.2083 0 17.3604 0.03125 14.6937 0.15C12.0312 0.275 10.2187 0.69375 8.625 1.3125C6.98125 1.95 5.58542 2.80625 4.19583 4.19583C2.80625 5.58542 1.94792 6.97917 1.3125 8.625C0.69375 10.2187 0.272917 12.0312 0.15 14.6937C0.025 17.3604 0 18.2083 0 25C0 31.7917 0.03125 32.6396 0.15 35.3063C0.275 37.9667 0.69375 39.7812 1.3125 41.375C1.95 43.0167 2.80625 44.4146 4.19583 45.8042C5.58542 47.1917 6.97917 48.0521 8.625 48.6875C10.2208 49.3042 12.0333 49.7271 14.6937 49.85C17.3604 49.975 18.2083 50 25 50C31.7917 50 32.6396 49.9687 35.3063 49.85C37.9667 49.725 39.7812 49.3042 41.375 48.6875C43.0167 48.05 44.4146 47.1917 45.8042 45.8042C47.1917 44.4146 48.0521 43.0229 48.6875 41.375C49.3042 39.7812 49.7271 37.9667 49.85 35.3063C49.975 32.6396 50 31.7917 50 25C50 18.2083 49.9687 17.3604 49.85 14.6937C49.725 12.0333 49.3042 10.2167 48.6875 8.625C48.05 6.98125 47.1917 5.58542 45.8042 4.19583C44.4146 2.80625 43.0229 1.94792 41.375 1.3125C39.7812 0.69375 37.9667 0.272917 35.3063 0.15C32.6396 0.025 31.7917 0 25 0ZM25 4.5C31.6729 4.5 32.4688 4.53333 35.1042 4.64792C37.5417 4.7625 38.8646 5.16667 39.7437 5.5125C40.9146 5.96458 41.7437 6.50625 42.6229 7.37917C43.4958 8.25417 44.0375 9.08542 44.4896 10.2562C44.8312 11.1354 45.2396 12.4583 45.35 14.8958C45.4687 
        17.5333 45.4958 18.325 45.4958 25C45.4958 31.675 45.4646 32.4688 45.3417 35.1042C45.2146 37.5417 44.8083 38.8646 44.4646 39.7437C43.9979 40.9146 43.4667 41.7437 42.5917 42.6229C41.7188 43.4958 40.875 44.0375 39.7167 44.4896C38.8417 44.8312 37.4979 45.2396 35.0604 45.35C32.4062 45.4687 31.625 45.4958 24.9375 45.4958C18.2479 45.4958 17.4667 45.4646 14.8146 45.3417C12.375 45.2146 11.0312 44.8083 10.1562 44.4646C8.97083 43.9979 8.15625 43.4667 7.28333 42.5917C6.40625 41.7188 5.84583 40.875 5.40833 39.7167C5.06458 38.8417 4.66042 37.4979 4.53333 35.0604C4.43958 32.4354 4.40625 31.625 4.40625 24.9687C4.40625 18.3104 4.43958 17.4979 4.53333 14.8417C4.66042 12.4042 5.06458 11.0625 5.40833 10.1875C5.84583 9 6.40625 8.1875 7.28333 7.31042C8.15625 6.4375 8.97083 5.875 10.1562 5.43958C11.0312 5.09375 12.3458 4.6875 14.7833 4.5625C17.4396 4.46875 18.2208 4.4375 24.9062 4.4375L25 4.5ZM25 12.1625C17.9062 12.1625 12.1625 17.9125 12.1625 25C12.1625 32.0938 17.9125 37.8375 25 37.8375C32.0938 37.8375 37.8375 32.0875 37.8375 25C37.8375 17.9062 32.0875 12.1625 25 12.1625ZM25 33.3333C20.3958 33.3333 16.6667 29.6042 16.6667 25C16.6667 20.3958 20.3958 16.6667 25 16.6667C29.6042 16.6667 33.3333 20.3958 33.3333 25C33.3333 29.6042 29.6042 33.3333 25 33.3333ZM41.3458 11.6562C41.3458 13.3125 40 14.6562 38.3458 14.6562C36.6896 14.6562 35.3458 13.3104 35.3458 11.6562C35.3458 10.0021 36.6917 8.65833 38.3458 8.65833C39.9979 8.65625 41.3458 
        10.0021 41.3458 11.6562Z" fill={color}/>
      </g>
      <defs>
        <clipPath id="clip0_26_237">
        <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};